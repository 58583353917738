@import url("https://fonts.googleapis.com/css?family=Lato:300,400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato";
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: linear-gradient(to right bottom, #ffeb3b, #03c2af);
  overflow-x: hidden;
}
