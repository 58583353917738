@import url(https://fonts.googleapis.com/css?family=Lato:300,400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,500i,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400,700,800&display=swap);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato";
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: linear-gradient(to right bottom, #ffeb3b, #03c2af);
  overflow-x: hidden;
}

#load {
  display: block;
  font-family: courier new;
  color: #fefcf6;
}

.imgbox {
  position: relative;
  top: 20%;
  left: 50%;
  height: auto;
  transform: translateX(-50%);
}

.loading-txt {
  margin-top: 100px;
  font-size: 50px;
  transform: translate(-50%, -50%);
}

#loader {
  position: absolute;
  top: 80%;
  left: 50%;
  width: 100px;
  transform: translate(-50%, -50%);
}
.pair {
  position: absolute;
  width: 60px;
  height: 10px;
}
.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fefcf6;
  border-radius: 50%;
}
.pair .dot-2 {
  right: 0;
}
.pair-2 {
  left: 50px;
}
.pair-1 {
  -webkit-animation: move 1000ms ease-in-out infinite;
          animation: move 1000ms ease-in-out infinite;
}
.pair-2 {
  animation: move 1000ms ease-in-out infinite reverse;
}

.pair-1 .dot-1 {
  animation: hide 1000ms ease-in-out infinite reverse;
  opacity: 0;
}
.pair-2 .dot-2 {
  -webkit-animation: hide 1000ms ease-in-out infinite;
          animation: hide 1000ms ease-in-out infinite;
  opacity: 0;
}

@-webkit-keyframes move {
  0% {
    transform: rotate(0deg);
  }
  50%,
  100% {
    transform: rotate(180deg);
  }
}

@keyframes move {
  0% {
    transform: rotate(0deg);
  }
  50%,
  100% {
    transform: rotate(180deg);
  }
}

@-webkit-keyframes hide {
  0%,
  49% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0%,
  49% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}

@media (max-width: 330px) {
  .loading-txt {
    display: none;
  }
  .imgbox {
    top: 10%;
  }
  #loader {
    position: relative;
    bottom: 40px;
  }
}

#pokemon {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-image: linear-gradient(
    to right,
    rgb(255, 255, 231),
    rgb(226, 224, 213)
  );
  width: 100vw;
  min-height: 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.input-container {
  display: block;
  width: 100vw;
  margin: 40px 0;
}

.pokemon-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
}

h1 {
  font-family: "Rubik", sans-serif;
  font-size: 60px;
  text-transform: uppercase;
  color: rgb(253, 147, 128);
  margin: 18px 1% 20px 0;
  line-height: 1.15;
  text-align: center;
  letter-spacing: 3px;
  font-weight: 600;
}

h1:hover {
  cursor: pointer;
}
.not-found {
  color: tomato;
  font-size: 40px;
}

@media (max-width: 560px) {
  .input-container {
    margin-top: 20px;
    margin-bottom: 60px;
  }
}

.pa2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

input {
  width: 300px;
  padding: 10px 20px;
  font-size: 20px;
  border: none;
  outline: none;
  box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: #7c7c7c;
}

input::-webkit-input-placeholder {
  color: #bbbaba;
}

input:-ms-input-placeholder {
  color: #bbbaba;
}

input::-ms-input-placeholder {
  color: #bbbaba;
}

input::placeholder {
  color: #bbbaba;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: url(https://img.icons8.com/flat_round/24/000000/delete-sign.png);
  opacity: 0.8;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 10px;
}

.footer {
  font-family: "Raleway", sans-serif;
  width: 100%;
  margin: 2% 0 5px 0;
}
p {
  text-align: center;
  padding: 0 5%;
  color: #34495e;
}

.footer a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  font-weight: 800;
  padding: 5px 3px;
  border-bottom: 1.5px solid #55c57a;
  transition: all 500ms;
}

.footer a:hover {
  color: white;
  background-color: #55c57a;
  transform: translateY(-3px);
}

.github-link {
  overflow: hidden;
  white-space: nowrap;
  position: fixed;
  top: 0;
  right: 0;
  width: 200px;
  height: 200px;
}

.github-link a {
  position: absolute;
  right: -60px;
  top: 60px;
  transform: rotate(45deg) scale(1);
  box-shadow: 0 0 10px #888;
  background-color: #7ddba1;
  color: #fff;
  display: block;
  font-family: "PT Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 1px 0;
  padding: 7px 50px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  transition: background-color 0.5s;
  transition: transform 0.5s;
  font-size: 14px;
}

.github-link a:hover {
  transform: rotate(45deg) scale(1.1);
}

.github-link a::before {
  top: 2px;
}

.github-link a::before,
.github-link a::after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  height: 1px;
  background-color: white;
}

.github-link a::after {
  bottom: 1px;
}

@media (max-width: 900px) {
  .github-link {
    display: none;
  }
}

.pagination {
  margin: 20px 4% 0 0;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.pagination ul {
  list-style: none;
}

.list-pages li {
  display: inline-block;
  padding: 8px 15px;
  font-size: 18px;
  margin: 0 5px;
}

.list-pages .pages {
  border-bottom: 2px solid rgb(238, 237, 237);
  transition: all 300ms;
}

.active {
  background-color: #26c569;
  color: white;
  border-radius: 3px;
}

.list-pages .pages:hover {
  border-bottom: 2px solid rgb(240, 72, 72);
  cursor: pointer;
}

.active:hover {
  cursor: pointer;
}

@media (max-width: 560px) {
  .pagination {
    margin-right: 0;
  }
}

.grass {
  background-color: rgb(222, 253, 224);
}
.fire {
  background-color: rgb(253, 223, 223);
}
.water {
  background-color: rgb(222, 243, 253);
}
.poison {
  background-image: linear-gradient(
    to right bottom,
    rgb(255, 234, 0),
    rgb(180, 60, 52)
  );
  color: white;
}
.bug {
  background-color: rgb(248, 213, 163);
}
.flying {
  background-color: rgb(245, 245, 245);
}
.electric {
  background-color: rgb(252, 247, 222);
}
.ground {
  background-color: rgb(244, 231, 218);
}
.fairy {
  background-color: rgb(252, 234, 255);
}
.normal {
  background-image: linear-gradient(to right bottom, #ffeb3b, #03c2af);
  color: white;
}
.fighting {
  background-color: rgb(250, 174, 60);
}
.psychic {
  background-color: tomato;
}
.ghost {
  background-color: tomato;
}
.rock {
  background-color: tomato;
}
.ice {
  background-color: tomato;
}
.dragon {
  background-color: tomato;
}
.dark {
  background-color: tomato;
}
.steel {
  background-color: tomato;
}

.pk-1 {
  position: relative;
  width: 260px;
  height: 430px;
  margin: 2%;
}

.pk-1:hover {
  cursor: pointer;
}

.pk-1::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 200px;
  background-image: linear-gradient(
    to right bottom,
    rgba(255, 234, 0, 0.8),
    rgba(180, 60, 52, 0.8)
  );
  opacity: 1;
  top: -1px;
  left: -2px;
  border-radius: 20px;
  transition: 0.6s;
}

.pk-1::after {
  content: "";
  position: absolute;
  width: 120px;
  height: 200px;
  background-image: linear-gradient(to right bottom, #ffeb3b, #03c2af);
  opacity: 1;
  bottom: -1px;
  right: -2px;
  border-radius: 20px;
  z-index: 1;
  transition: 0.6s;
}

.pk-1:hover::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 200px;
  background-image: linear-gradient(
    to right bottom,
    rgba(255, 234, 0, 0.8),
    rgba(180, 60, 52, 0.8)
  );
  opacity: 1;
  top: 230px;
  left: -2px;
  border-radius: 20px;
  transition: 0.6s;
}

.pk-1:hover::after {
  content: "";
  position: absolute;
  width: 120px;
  height: 200px;
  background-image: linear-gradient(to right bottom, #ffeb3b, #03c2af);
  opacity: 1;
  bottom: 230px;
  right: -2px;
  border-radius: 20px;
  z-index: 1;
  transition: 0.6s;
}

.box {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 240px;
  height: 410px;
  opacity: 1;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(255, 255, 255);
  border-radius: 20px;
  z-index: 2;
}

.img-container {
  margin: 8% auto 15% auto;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  width: 180px;
  height: 180px;
  text-align: center;
}

.img-container img {
  margin-top: 20px;
  max-width: 90%;
}

.info {
  text-align: center;
  font-size: 17px;
}

.name {
  margin: 15px 0 7px;
  letter-spacing: 1px;
  font-size: 26px;
}

.number {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 18px;
  padding: 5px 10px;
}

.submit-button {
  background-image: linear-gradient(
    to right bottom,
    rgba(255, 234, 0, 0.8),
    rgba(180, 60, 52, 0.8)
  );
  padding: 5px 20px;
  width: 100px;
  text-align: center;
  margin: 25px 0 0 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.5);
  transition: 0.6s;
}

.submit-button:hover {
  padding: 8px 20px;
  box-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

.smaller-font {
  font-size: 23px;
}

.modal-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  width: 60vw;
  min-height: 30vh;
  background: white;
  border: 6px solid #001f61;
  border-radius: 20px;
  -webkit-animation: openModal 1s;
          animation: openModal 1s;
}

.modal-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-loading i {
  font-size: 50px;
  color: #001f61;
  -webkit-animation: loadingCircle 2s infinite;
          animation: loadingCircle 2s infinite;
}

.modal-box button {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 34px;
  background-color: #e3e4e7;
  border: 1px solid #cacdd4;
  padding: 5px 5px 2px 5px;
  cursor: pointer;
  border-radius: 3px;
}

.modal-box button:hover i,
.modal-box button:link i,
.modal-box button:active i {
  color: tomato;
}

.modal-box button i {
  color: #4a64b3;
}

.modal-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px 10px 10px;
  background-color: #001f61;
  color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.modal-title span {
  color: tomato;
}

.modal-box h2 {
  margin: 80px 0;
  text-align: center;
  color: #97a5ce;
  text-transform: uppercase;
}

.modal-icons {
  display: flex;
  justify-content: center;
}

.modal-icons a {
  margin: 10px;
  text-decoration: none;
  background: #fff;
  width: 60px;
  height: 60px;
  position: relative;
  border-radius: 50%;
  border: 1px solid #dce1f2;
}

.modal-icons i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #001f61;
}

.modal-icons a:hover {
  border-color: #ff6a00;
  box-shadow: 0 9px 12px -9px #ff6a00;
}

@-webkit-keyframes loadingCircle {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loadingCircle {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes openModal {
  from {
    top: 0%;
  }

  to {
    top: 50%;
  }
}

@keyframes openModal {
  from {
    top: 0%;
  }

  to {
    top: 50%;
  }
}

@media (max-width: 900px) {
  .modal-box {
    width: 90vw;
  }
}

@media (max-width: 360px) {
  .modal-box {
    width: 96vw;
  }

  .modal-icons a {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 300px) {
  .modal-box {
    width: 100vw;
  }

  .modal-icons a {
    width: 40px;
    height: 40px;
  }

  .modal-icons i {
    font-size: 20px;
  }
}

.sorted-box {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  border: 0;
  outline: none;
}

.label-box {
  width: 260px;
  padding: 10.5px 0 10.5px 16px;
  background-color: white;
  cursor: pointer;
  position: relative;
  border: 1px solid rgb(228, 224, 224);
  border-radius: 5px;
}

.fa-sort {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.inactive {
  color: rgb(158, 158, 158);
}

.show-box {
  display: block;
  width: 260px;
  position: absolute;
  z-index: 5;
  background-color: white;
  top: 42px;
  border-radius: 5px;
  overflow: hidden;
  -webkit-animation: moveBoxDown 0.4s;
          animation: moveBoxDown 0.4s;
}

.main-box {
  padding: 10.5px 12px 10.5px 16px;
  cursor: pointer;
  transition: all 0.5s;
}

.show-box i {
  margin-right: 12px;
}

.main-box:nth-child(1):hover {
  background-color: tomato;
  color: white;
  border-radius: 5px;
  -webkit-animation: moveUpText 0.5s;
          animation: moveUpText 0.5s;
}
.main-box:nth-child(2):hover {
  background-color: #000;
  color: white;
  border-radius: 5px;
  -webkit-animation: moveUpText 0.5s;
          animation: moveUpText 0.5s;
}
.main-box:nth-child(3):hover {
  background-color: #0057ff;
  color: white;
  border-radius: 5px;
  -webkit-animation: moveUpText 0.5s;
          animation: moveUpText 0.5s;
}
.main-box:nth-child(4):hover {
  background-color: #32c766;
  color: white;
  border-radius: 5px;
  -webkit-animation: moveUpText 0.5s;
          animation: moveUpText 0.5s;
}
.main-box:nth-child(5):hover {
  background-color: #006400;
  color: white;
  border-radius: 5px;
  -webkit-animation: moveUpText 0.5s;
          animation: moveUpText 0.5s;
}
.main-box:nth-child(6):hover {
  background-color: #f48024;
  color: white;
  border-radius: 5px;
  -webkit-animation: moveUpText 0.5s;
          animation: moveUpText 0.5s;
}

.fa-sort-numeric-down {
  color: tomato;
  border-radius: 5px;
}

.fa-sort-numeric-down-alt {
  color: #000;
  border-radius: 5px;
}

.fa-sort-alpha-down {
  color: #0057ff;
  border-radius: 5px;
}

.fa-sort-alpha-down-alt {
  color: #32c766;
  border-radius: 5px;
}

.fa-paw {
  color: #006400;
  border-radius: 5px;
}

.fa-typo3 {
  color: #f48024;
  border-radius: 5px;
}

.main-box:hover .fa-sort-numeric-down {
  color: rgb(255, 253, 253);
  border-radius: 5px;
  -webkit-animation: moveDownIcon 0.5s;
          animation: moveDownIcon 0.5s;
}

.main-box:hover .fa-sort-numeric-down-alt {
  color: rgb(255, 253, 253);
  border-radius: 5px;
  -webkit-animation: moveDownIcon 0.5s;
          animation: moveDownIcon 0.5s;
}

.main-box:hover .fa-sort-alpha-down {
  color: rgb(255, 253, 253);
  border-radius: 5px;
  -webkit-animation: moveDownIcon 0.5s;
          animation: moveDownIcon 0.5s;
}

.main-box:hover .fa-sort-alpha-down-alt {
  color: rgb(255, 253, 253);
  border-radius: 5px;
  -webkit-animation: moveDownIcon 0.5s;
          animation: moveDownIcon 0.5s;
}

.main-box:hover .fa-paw {
  color: rgb(255, 253, 253);
  border-radius: 5px;
  -webkit-animation: moveDownIcon 0.5s;
          animation: moveDownIcon 0.5s;
}

.main-box:hover .fa-typo3 {
  color: rgb(255, 253, 253);
  border-radius: 5px;
  -webkit-animation: moveDownIcon 0.5s;
          animation: moveDownIcon 0.5s;
}

@-webkit-keyframes moveDownIcon {
  from {
    transform: translateY(-200%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes moveDownIcon {
  from {
    transform: translateY(-200%);
  }
  to {
    transform: translateY(0%);
  }
}

@-webkit-keyframes moveUpText {
  from {
    transform: translateY(20%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes moveUpText {
  from {
    transform: translateY(20%);
  }
  to {
    transform: translateY(0%);
  }
}

@-webkit-keyframes moveBoxDown {
  from {
    transform: translateY(-20%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes moveBoxDown {
  from {
    transform: translateY(-20%);
  }
  to {
    transform: translateY(0%);
  }
}

