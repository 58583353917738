.pa2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

input {
  width: 300px;
  padding: 10px 20px;
  font-size: 20px;
  border: none;
  outline: none;
  box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: #7c7c7c;
}

input::placeholder {
  color: #bbbaba;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: url(https://img.icons8.com/flat_round/24/000000/delete-sign.png);
  opacity: 0.8;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 10px;
}
