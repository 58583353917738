.pagination {
  margin: 20px 4% 0 0;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.pagination ul {
  list-style: none;
}

.list-pages li {
  display: inline-block;
  padding: 8px 15px;
  font-size: 18px;
  margin: 0 5px;
}

.list-pages .pages {
  border-bottom: 2px solid rgb(238, 237, 237);
  transition: all 300ms;
}

.active {
  background-color: #26c569;
  color: white;
  border-radius: 3px;
}

.list-pages .pages:hover {
  border-bottom: 2px solid rgb(240, 72, 72);
  cursor: pointer;
}

.active:hover {
  cursor: pointer;
}

@media (max-width: 560px) {
  .pagination {
    margin-right: 0;
  }
}
