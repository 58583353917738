#load {
  display: block;
  font-family: courier new;
  color: #fefcf6;
}

.imgbox {
  position: relative;
  top: 20%;
  left: 50%;
  height: auto;
  transform: translateX(-50%);
}

.loading-txt {
  margin-top: 100px;
  font-size: 50px;
  transform: translate(-50%, -50%);
}

#loader {
  position: absolute;
  top: 80%;
  left: 50%;
  width: 100px;
  transform: translate(-50%, -50%);
}
.pair {
  position: absolute;
  width: 60px;
  height: 10px;
}
.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fefcf6;
  border-radius: 50%;
}
.pair .dot-2 {
  right: 0;
}
.pair-2 {
  left: 50px;
}
.pair-1 {
  animation: move 1000ms ease-in-out infinite;
}
.pair-2 {
  animation: move 1000ms ease-in-out infinite reverse;
}

.pair-1 .dot-1 {
  animation: hide 1000ms ease-in-out infinite reverse;
  opacity: 0;
}
.pair-2 .dot-2 {
  animation: hide 1000ms ease-in-out infinite;
  opacity: 0;
}

@keyframes move {
  0% {
    transform: rotate(0deg);
  }
  50%,
  100% {
    transform: rotate(180deg);
  }
}

@keyframes hide {
  0%,
  49% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}

@media (max-width: 330px) {
  .loading-txt {
    display: none;
  }
  .imgbox {
    top: 10%;
  }
  #loader {
    position: relative;
    bottom: 40px;
  }
}
