@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,700,800&display=swap");

.footer {
  font-family: "Raleway", sans-serif;
  width: 100%;
  margin: 2% 0 5px 0;
}
p {
  text-align: center;
  padding: 0 5%;
  color: #34495e;
}

.footer a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  font-weight: 800;
  padding: 5px 3px;
  border-bottom: 1.5px solid #55c57a;
  transition: all 500ms;
}

.footer a:hover {
  color: white;
  background-color: #55c57a;
  transform: translateY(-3px);
}
