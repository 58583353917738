@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,500i,700&display=swap");

#pokemon {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-image: linear-gradient(
    to right,
    rgb(255, 255, 231),
    rgb(226, 224, 213)
  );
  width: 100vw;
  min-height: 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.input-container {
  display: block;
  width: 100vw;
  margin: 40px 0;
}

.pokemon-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
}

h1 {
  font-family: "Rubik", sans-serif;
  font-size: 60px;
  text-transform: uppercase;
  color: rgb(253, 147, 128);
  margin: 18px 1% 20px 0;
  line-height: 1.15;
  text-align: center;
  letter-spacing: 3px;
  font-weight: 600;
}

h1:hover {
  cursor: pointer;
}
.not-found {
  color: tomato;
  font-size: 40px;
}

@media (max-width: 560px) {
  .input-container {
    margin-top: 20px;
    margin-bottom: 60px;
  }
}
