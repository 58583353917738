.github-link {
  overflow: hidden;
  white-space: nowrap;
  position: fixed;
  top: 0;
  right: 0;
  width: 200px;
  height: 200px;
}

.github-link a {
  position: absolute;
  right: -60px;
  top: 60px;
  transform: rotate(45deg) scale(1);
  box-shadow: 0 0 10px #888;
  background-color: #7ddba1;
  color: #fff;
  display: block;
  font-family: "PT Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 1px 0;
  padding: 7px 50px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  transition: background-color 0.5s;
  transition: transform 0.5s;
  font-size: 14px;
}

.github-link a:hover {
  transform: rotate(45deg) scale(1.1);
}

.github-link a::before {
  top: 2px;
}

.github-link a::before,
.github-link a::after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  height: 1px;
  background-color: white;
}

.github-link a::after {
  bottom: 1px;
}

@media (max-width: 900px) {
  .github-link {
    display: none;
  }
}
